import { createMuiTheme } from "@material-ui/core/styles";
import { createTheme } from "@mui/material/styles";

const Colors = {
  primary: "#EB7B00",
  primaryMuted: "#feedda",
  primaryTranslucent: "rgba(235, 123, 0, 0.2)",
  secondary: "#241513",
  tertiary: "#36cea0",
  dark: "#212121",
  darkBlue: "#006088",
  active: "#36cea0",
  activeOp: "rgb(54, 206, 160, .8)",
  paused: "#ffca28",
  pausedOp: "rgb(255, 202, 40, .8)",
  archived: "#cfd8dc",
  success: "#36cea0",
  danger: "#d54800",
  dangerOp: "rgb(213, 72, 0, .8)",
  tableHeader: "#eceff1",
  tableStripedRow: "#f7f6f6",
  tableRowHover: "#E6F4FA",
  gray: "#b0bec5",
  inputBorder: "#e0e0e0",
  graph1: "#374375",
  graph2: "#656e94",
  graph3: "#969cb6",
  buttonDefault: "#f5f5f5",
  grayText: "#9e9e9e",
  lightGray: "#c5c5c5",
  gray40: "#666666",
  gray000: "#fafafa",
  gray100: "#f0f0f0",
  gray300: "#d7d7d7",
  gray400: "#585858",
  gray500: "#6E6D72",
  gray700: "#383e3f",
  primaryBorderColor: "#c3d2e2",
  tableHeadBackground: "#e3e7ed",
  tableBorder: "#bac4ce",
  link: "#03a9f4",
  termLink: "#96defe",
  labelWarning: "#f0ad4e",
  yellow: "#f7bf39",
  enabled: "#26b2a4",
  positive: {
    admiration: "#006400",
    amusement: "#98fb98",
    approval: "#00ff00",
    caring: "#4682b4",
    desire: "#808000",
    excitement: "#1e90ff",
    gratitude: "#00008b",
    joy: "#00ffff",
    love: "#8fbc8f",
    optimism: "#9acd32",
    pride: "#0000ff",
    relief: "#add8e6",
  },
  ambiguous: {
    confusion: "#bc8f8f",
    curiosity: "#ffe4b5",
    realization: "#8b4513",
    surprise: "#ff8c00",
    neutral: "#ffd700",
  },
  negative: {
    anger: "#dc143c",
    annoyance: "#ffa07a",
    disappointment: "#8a2be2",
    disapproval: "#2f4f4f",
    disgust: "#483d8b",
    embarrassment: "#ee82ee",
    fear: "#ff1493",
    grief: "#b03060",
    nervousness: "#7f007f",
    remorse: "#ff00ff",
    sadness: "#ff4500",
  },
};

const muiThemeV5 = createTheme({
  typography: { fontFamily: ["Google Sans"], useNextVariants: true },
});

const muiTheme = createMuiTheme({
  palette: {
    primary: { main: Colors.secondary },
    secondary: { main: Colors.primary },
  },
  typography: { fontFamily: ["Google Sans"], useNextVariants: true },
  tabs: {
    backgroundColor: "#ffffff",
    textColor: Colors.secondary,
    selectedTextColor: Colors.primary,
  },
  checkbox: {
    boxColor: Colors.inputBorder,
    checkedColor: Colors.primary,
  },
  textField: {
    focusColor: Colors.primary,
    borderColor: Colors.inputBorder,
  },
});

export { Colors, muiTheme, muiThemeV5 };
export default {
  Colors,
  muiTheme,
  muiThemeV5,
};
